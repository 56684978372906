import React from "react";
import { Link } from "react-router-dom";
import ModalError from "./ModalError.js";
import HeaderAds from "./HeaderAds.js";

const axios = require("axios");

class addTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DonePages: true,
      selectedTime: null,
      darkmode:1,
      addedText: "",
      displayFooter: true,
      errorModal: false,
      darkmodepath:"",
      erroMessage: ""
    };
    this.clickButton = this.clicKButton.bind(this);
    this.clickDone = this.clickDone.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.changeText = this.changeText.bind(this);
  }

  onBlur() {
    this.setState({ displayFooter: true });
  }
  onFocus() {
    this.setState({ displayFooter: false });
  }
  changeText(event) {
    if (this.state.addedText.length > 51) {
      return;
    }
    this.setState({ addedText: event.target.value });
  }
  //Carica i task da Back end
  componentDidMount() {
    if(localStorage.getItem("darkmode")==1){
      this.setState({darkmodepath:"dark/"});
    }
    else{
    }
  }
  //Gestione del click sul bottone
  clicKButton(value, id) {
    this.setState({ selectedTime: value });
  }

  //Gestione click sul check
  //Gestione click sul done
  clickDone() {
    //Chiamata al servizio di done
    if (
      this.state.selectedTime == null ||
      this.state.selectedTime == 0 ||
      this.state.addedText == "" ||
      this.state.addedText.length < 1
    ) {
      return false;
    }
    var params = {
      nome: this.state.addedText,
      tempo: this.state.selectedTime
    };
    fetch("https://app.ananke-app.com/insertTask", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token"),
        credentials: "include"
      },
      body: JSON.stringify(params)
    })
      .then(res => res.json())
      .then(
        result => {
          this.setState({ DonePages: false, selectedTime: 0, addedText: "" });
        },
        error => {
          this.setState({ errorModal: true, erroMessage: "Error in add task" });
          return;
        }
      );
    setTimeout(() => {
      this.setState({ DonePages: true });
    }, 2000);
  }

  render() {
    return (
      <React.Fragment>
        <div className="ads" style={{ height: "13vh", textAlign: "center" }}>
          {localStorage.getItem("premium") != 2 && <HeaderAds></HeaderAds>}
        </div>
        <div
          className="immagine-ask"
          style={{
            textAlign: "left",
            height: "77vh",
            backgroundColor: ""
          }}
        >
          {this.state.errorModal && (
            <ModalError messageError={this.state.erroMessage}></ModalError>
          )}
          <div className="" style={{ textAlign: "left", paddingLeft: "15%" }}>
            {this.state.DonePages && (
              <label
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "1.3em",
                  paddingTop: "5%"
                }}
                htmlFor="textInsert"
              >
                Task Title
              </label>
            )}
            <br />
            {this.state.DonePages && (
              <input
                type="text"
                style={{
                  fontSize: "1.3em",
                  border: "0",
                  borderBottom: "1px solid #b5b5b5b5"
                }}
                placeholder="Write the title here"
                id="textInsert"
                autoComplete="off"
                onChange={this.changeText}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                value={this.state.addedText}
              ></input>
            )}
          </div>
          {!this.state.DonePages && (
            <div style={{ textAlign: "center", fontSize: "1.2em" }}>
              <img
                style={{ width: "15%", marginTop: "0" }}
                className=""
                onClick={this.clickDone}
                src={process.env.PUBLIC_URL + "/button-check.svg"}
              ></img>
              <br />
              <br />
              <p>
                <b>Task added</b>
              </p>
            </div>
          )}
          <div
            className="box-bottoni"
            style={{
              backgroundColor: "",
              height: "25vh",
              width: "100%",
              marginTop: "10%"
            }}
          >
            <div className="col12">
            <label
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "1.3em",
                  paddingLeft: "15%",
                  paddingRight:"15%"
                }}
                htmlFor="textInsert"
              >
                Select minutes
              </label>            </div>
            <div className="col3-left-extreme"></div>
            <div className="col3-left">
              <img
                onClick={() => this.clicKButton(5, 0)}
                src={
                  this.state.selectedTime == 5
                    ? process.env.PUBLIC_URL + "/button/"+this.state.darkmodepath+"button-5-active.svg"
                    : process.env.PUBLIC_URL +
                      "/button/"+this.state.darkmodepath+"button-5-without-circle.svg"
                }
                value="5"
                style={{ width: "80%", marginBottom: "10px" }}
              />
              <img
                onClick={() => this.clicKButton(30, 3)}
                src={
                  this.state.selectedTime == 30
                    ? process.env.PUBLIC_URL + "/button/"+this.state.darkmodepath+"button-30-active.svg"
                    : process.env.PUBLIC_URL +
                      "/button/"+this.state.darkmodepath+"button-30-without-circle.svg"
                }
                value="30"
                style={{ width: "80%" }}
              />
            </div>
            <div className="col3-center">
              <img
                onClick={() => {
                  this.clicKButton(10, 1);
                }}
                src={
                  this.state.selectedTime == 10
                    ? process.env.PUBLIC_URL + "/button/"+this.state.darkmodepath+"button-10-active.svg"
                    : process.env.PUBLIC_URL +
                      "/button/"+this.state.darkmodepath+"button-10-without-circle.svg"
                }
                value="10"
                style={{ width: "80%", marginBottom: "10px" }}
              />
              <img
                onClick={() => {
                  this.clicKButton(45, 4);
                }}
                src={
                  this.state.selectedTime == 45
                    ? process.env.PUBLIC_URL + "/button/"+this.state.darkmodepath+"button-45-active.svg"
                    : process.env.PUBLIC_URL +
                      "/button/"+this.state.darkmodepath+"button-45-without-circle.svg"
                }
                value="45"
                style={{ width: "80%" }}
              />
            </div>
            <div className="col3-right">
              <img
                onClick={() => {
                  this.clicKButton(20, 2);
                }}
                src={
                  this.state.selectedTime == 20
                    ? process.env.PUBLIC_URL + "/button/"+this.state.darkmodepath+"button-20-active.svg"
                    : process.env.PUBLIC_URL +
                      "/button/"+this.state.darkmodepath+"button-20-without-circle.svg"
                }
                value="20"
                style={{ width: "80%", marginBottom: "10px" }}
              />
              <img
                onClick={() => {
                  this.clicKButton(60, 5);
                }}
                src={
                  this.state.selectedTime == 60
                    ? process.env.PUBLIC_URL + "/button/"+this.state.darkmodepath+"button-60-active.svg"
                    : process.env.PUBLIC_URL +
                      "/button/"+this.state.darkmodepath+"button-60-without-circle.svg"
                }
                value="60"
                style={{ width: "80%" }}
              />
            </div>
            <img
              style={{ marginLeft: "39%", width: "21%", marginTop: "5%" }}
              onClick={this.clickDone}
              src={process.env.PUBLIC_URL + "/button/"+this.state.darkmodepath+"button-add.svg"}
            ></img>
            <br />
          </div>
        </div>





        {this.state.displayFooter && localStorage.getItem("darkmode")!=1 &&(
          <div className="bottoni" style={{ height: "100%" }}>
            <div
              className="col-footer-left"
              style={{ borderRight: "1px solid #b2b2b2" }}
            >
              {localStorage.getItem("premium") != 2 && (
                <Link to={`/buy-pro/`}>
                  <p className="text-ads blink-1">
                    <b>Stop Ads!</b>
                    <br />
                    <span style={{ fontWeight: "300" }}>Upgrade Pro</span>
                  </p>
                </Link>
              )}
              {localStorage.getItem("premium") == 2 && (
                <p className="text-ads">
                  <b>You are</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Pro member</span>
                </p>
              )}
            </div>
            <div
              className="col-footer-center"
              style={{ borderRight: "1px solid #b2b2b2", textAlign: "center" }}
            >
              <Link to={`/add-task/`}>
                <p className="text-task" style={{ textAlign: "center" }}>
                  <b style={{ fontSize: "1.4em" }}>&#43;</b>
                  <br />
                  <span style={{ fontWeight: "300",textDecoration:"underline" }}>Add Task</span>
                </p>
              </Link>
            </div>

            <div className="col-footer-right">
              <Link to={"/"}>
                {" "}
                <img
                  style={{ marginBottom: "20%", width: "50%" }}
                  src={process.env.PUBLIC_URL + "/button-home.svg"}
                ></img>
              </Link>
            </div>
          </div>
        )}
        {this.state.displayFooter && localStorage.getItem("darkmode")==1 && (
          <div className="bottoni" style={{ height: "100%" }}>
            <div
              className="col-footer-left"
              style={{ borderRight: "1px solid #b2b2b2" }}
            >
              {localStorage.getItem("premium") != 2 && (
                <Link to={`/buy-pro/`}>
                  <p className="text-ads-dark blink-1">
                    <b>Stop Ads!</b>
                    <br />
                    <span style={{ fontWeight: "300" }}>Upgrade Pro</span>
                  </p>
                </Link>
              )}
              {localStorage.getItem("premium") == 2 && (
                <p className="text-ads-dark">
                  <b>You are</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Pro member</span>
                </p>
              )}
            </div>
            <div
              className="col-footer-center"
              style={{ borderRight: "1px solid #b2b2b2", textAlign: "center" }}
            >
              <Link to={`/add-task/`}>
                <p className="text-task-dark" style={{ textAlign: "center" }}>
                  <b style={{ fontSize: "1.4em" }}>&#43;</b>
                  <br />
                  <span style={{ fontWeight: "300",textDecoration:"underline" }}>Add Task</span>
                </p>
              </Link>
            </div>

            <div className="col-footer-right">
              <Link to={"/"}>
                {" "}
                <img
                  style={{ marginBottom: "25%", width: "50%" }}
                  src={process.env.PUBLIC_URL + "/dark/button-home.svg"}
                ></img>
              </Link>
            </div>
          </div>
        )}





      </React.Fragment>
    );
  }
}
export default addTask;
