import React, { Component } from "react";

class HeaderAds extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      urlAffilite: [
        "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=wedding&banner=0GFR5CWDFKC0FJ2FB0G2&f=ifr&linkID=46a37700f633258a77e21559e5772afa&t=ananke0d-20&tracking_id=ananke0d-20",
        "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=amzn_biz_bounty&banner=1CBSC7EGBAJ74Q2EWK02&f=ifr&linkID=96ed120af8d55ca4c82282214e518b49&t=ananke0d-20&tracking_id=ananke0d-20",
        "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=amazonfresh&banner=0KEJB744CPSX07A48W02&f=ifr&linkID=ea0ef2a31f9987ea10d0ba272bb42d90&t=ananke0d-20&tracking_id=ananke0d-20",
        "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=amzn_music_bounty&banner=1YCBX98M6D00767B9PR2&f=ifr&linkID=415b1f452fadea3de225826155eec771&t=ananke0d-20&tracking_id=ananke0d-20",
        "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=wedding&banner=0GFR5CWDFKC0FJ2FB0G2&f=ifr&linkID=46a37700f633258a77e21559e5772afa&t=ananke0d-20&tracking_id=ananke0d-20",
        "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=audiblegiftmemberships&banner=1G351WPT1CW7H6GMXW02&f=ifr&linkID=a93dc49150dfd5a480d8fae9396a00cb&t=ananke0d-20&tracking_id=ananke0d-20",
        "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=primeent&banner=1JYFV583HV833YKS3M82&f=ifr&linkID=78bacbf9e0b641da4746687eb00923c5&t=ananke0d-20&tracking_id=ananke0d-20",
        "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=audible&banner=1PSTJPA9D4ZMNJS5JDG2&f=ifr&linkID=121c211c582a7a214204c5f2aba9ee5f&t=ananke0d-20&tracking_id=ananke0d-20",
        "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=audible&banner=1MT86MHBPYDXGSS2DYG2&f=ifr&linkID=1517d1ba9d243a2e62caf6d96b31eafc&t=ananke0d-20&tracking_id=ananke0d-20"
      ],
      selectedUrl:
        "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=wedding&banner=0GFR5CWDFKC0FJ2FB0G2&f=ifr&linkID=46a37700f633258a77e21559e5772afa&t=ananke0d-20&tracking_id=ananke0d-20"
    };
  }
  componentDidMount() {
    var item = this.state.urlAffilite[Math.floor(Math.random() * this.state.urlAffilite.length)];
    this.setState({selectedUrl:item});
  }
  render() {
    return (
      <iframe
        src={this.state.selectedUrl}
        style={{ width: "320px", height: "70px", border: "0" }}
      ></iframe>
    );
  }
}

export default HeaderAds;
