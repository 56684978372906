import React from "react";
import { Link, Redirect } from "react-router-dom";
import HeaderAds from "./HeaderAds.js";
import ModalError from "./ModalError.js";
import CheckoutForm from "./CheckoutForm";

class Profilo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {checked:false};
    this.logout = this.logout.bind(this);
    this.darkMode = this.darkMode.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem("darkmode") == 1) {
      this.setState({checked:true});
    } else {
      this.setState({checked:false});
    }
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("nome");
    localStorage.removeItem("googleToken");
    localStorage.removeItem("facebookToken");
    localStorage.removeItem("immagineProfilo");
    window.location.reload();
  }
  darkMode() {
    if(localStorage.getItem("darkmode")==1){
      localStorage.setItem("darkmode", 0);
      this.setState({checked:false});
    }
    else{
      localStorage.setItem("darkmode", 1);
      this.setState({checked:true});
    }
    window.location.reload();
  }
  lightMode() {
    localStorage.setItem("darkmode", 0);
    window.location.reload();
  }
  render() {
    return (
      <React.Fragment>
        {localStorage.getItem("token") == "undefined" ||
        !localStorage.getItem("token") ||
        localStorage.getItem("token") == null ? (
          <Redirect to="/login" />
        ) : (
          ""
        )}
        <div className="ads" style={{ height: "13vh", textAlign: "center" }}>
          {localStorage.getItem("premium") != 2 && <HeaderAds></HeaderAds>}
        </div>
        <div
          style={{
            textAlign: "left",
            height: "77vh"
          }}
        >
          <img
            src={localStorage.getItem("immagineProfilo")}
            className=""
            style={{
              borderRadius: "50%",
              width: "30%",
              height: "auto",
              marginLeft: "5%"
            }}
          />

          <div className="logout">
            <p style={{ fontWeight: "bold" }}>Account Setting</p>
            <p>{localStorage.getItem("nome")}</p>
            <p>{localStorage.getItem("email")}</p>
            <button className="logout-button" onClick={this.logout}>
              Logout
            </button>
           
          </div>

          {localStorage.getItem("premium") == 0 && (
            <div
              className="logout"
              style={{ margin: "0 auto", textAlign: "left", paddingLeft: "5%" }}
            >
           
              <p
                style={{
                  fontWeight: "bold",
                  marginBottom: "0",
                  lineHeight: "1.2em"
                }}
              >
                Free Plan
              </p>
              <ul
                style={{
                  marginBottom: "0",
                  color: "#b5b5b5",
                  lineHeight: "1.2em",
                  paddingLeft: "5%"
                }}
              >
                <li style={{ fontWeight: "600" }}>
                  include <span style={{ fontWeight: "200" }}>Ads</span>
                </li>
                <li style={{ fontWeight: "600" }}>
                  do not include{" "}
                  <span style={{ fontWeight: "200" }}>Dark mode</span>
                </li>
              </ul>
              <Link to={`/buy-pro/`}>
                <button className="bottone-buy-profile">Upgrade Pro</button>
              </Link>
            </div>
          )}

{localStorage.getItem("premium") == 1 && (
            <div
              className="logout"
              style={{ margin: "0 auto", textAlign: "left", paddingLeft: "5%" }}
            >
           
              <p
                style={{
                  fontWeight: "bold",
                  marginBottom: "0",
                  lineHeight: "1.2em"
                }}
              >
                Dark Mode Plan
              </p>
              <ul
                style={{
                  marginBottom: "0",
                  color: "#b5b5b5",
                  lineHeight: "1.2em",
                  paddingLeft: "5%"
                }}
              >
                <li style={{ fontWeight: "600" }}>
                  include <span style={{ fontWeight: "200" }}>Ads</span>
                </li>
              </ul>
              <Link to={`/buy-pro/`}>
                <button className="bottone-buy-profile">Upgrade Pro</button>
              </Link>
            </div>
          )}

          <div
            className="buypro row"
            style={{
              margin: "0 auto",
              textAlign: "left",
              marginTop: "3%",
            }}
          >
            <div className="col-9">
            <p style={{ fontWeight: "bold", marginBottom: "0" }}>Switch to {localStorage.getItem("darkmode")==1? <span>Light Mode</span> : <span>Dark Mode</span>}</p>
            <label className="p-switch p-switch-xl" style={{marginTop:"15px"}}>
                <input type="checkbox" name="switch" checked={this.state.checked} onChange={this.darkMode} disabled={localStorage.getItem("premium") == 0}/>
                <span className="p-switch-style"></span>
              </label>
            </div>
            <div className="col-3" style={{textAlign:"left"}}>
            <p style={{ fontWeight: "bold", marginBottom: "0" }}>Version</p>
            <p>1.1.1</p>
            </div>
            </div>
        </div>

        {localStorage.getItem("darkmode") != 1 && (
          <div className="bottoni" style={{ height: "100%" }}>
            <div
              className="col-footer-left"
              style={{ borderRight: "1px solid #b2b2b2" }}
            >
              {localStorage.getItem("premium") != 2 && (
                <Link to={`/buy-pro/`}>
                  <p className="text-ads blink-1">
                    <b>Stop Ads!</b>
                    <br />
                    <span style={{ fontWeight: "300" }}>Upgrade Pro</span>
                  </p>
                </Link>
              )}
              {localStorage.getItem("premium") == 2 && (
                <p className="text-ads">
                  <b>You are</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Pro member</span>
                </p>
              )}
            </div>
            <div
              className="col-footer-center"
              style={{ borderRight: "1px solid #b2b2b2", textAlign: "center" }}
            >
              <Link to={`/add-task/`}>
                <p className="text-task" style={{ textAlign: "center" }}>
                  <b style={{ fontSize: "1.4em" }}>&#43;</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Add Task</span>
                </p>
              </Link>
            </div>

            <div className="col-footer-right">
              <Link to={"/"}>
                {" "}
                <img
                  style={{ marginBottom: "20%", width: "50%" }}
                  src={process.env.PUBLIC_URL + "/button-home.svg"}
                ></img>
              </Link>
            </div>
          </div>
        )}
        {localStorage.getItem("darkmode") == 1 && (
          <div className="bottoni" style={{ height: "100%" }}>
            <div
              className="col-footer-left"
              style={{ borderRight: "1px solid #b2b2b2" }}
            >
              {localStorage.getItem("premium") != 2 && (
                <Link to={`/buy-pro/`}>
                  <p className="text-ads-dark blink-1">
                    <b>Stop Ads!</b>
                    <br />
                    <span style={{ fontWeight: "300" }}>Upgrade Pro</span>
                  </p>
                </Link>
              )}
              {localStorage.getItem("premium") == 2 && (
                <p className="text-ads-dark">
                  <b>You are</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Pro member</span>
                </p>
              )}
            </div>
            <div
              className="col-footer-center"
              style={{ borderRight: "1px solid #b2b2b2", textAlign: "center" }}
            >
              <Link to={`/add-task/`}>
                <p className="text-task-dark" style={{ textAlign: "center" }}>
                  <b style={{ fontSize: "1.4em" }}>&#43;</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Add Task</span>
                </p>
              </Link>
            </div>

            <div className="col-footer-right">
              <Link to={"/"}>
                {" "}
                <img
                  style={{ marginBottom: "20%", width: "50%" }}
                  src={process.env.PUBLIC_URL + "/dark/button-home.svg"}
                ></img>
              </Link>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default Profilo;
