import React, { Component } from "react";
import { Modal } from "react-bootstrap";

class AdsPro extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: true,
      urlAffilite: [
        {url:"https://rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=kindlerotating&f=ifr&linkID=54a0dec832ca343ed64dc7bac9a2bc55&t=ananke0d-20&tracking_id=ananke0d-20",size:1},
        {url:"https://rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=musicandentertainmentrot&f=ifr&linkID=5b6ef7212e2d06c9013555a26fc59227&t=ananke0d-20&tracking_id=ananke0d-20",size:1},
        {url:"https://rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=amazonvideoondemand&banner=15GA2X24AC2V29CHDKG2&f=ifr&linkID=9f636c9ea77295f70af248e75a48bf68&t=ananke0d-20&tracking_id=ananke0d-20",size:1},
        {url:"https://rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=audiblelostgirls&banner=0C8XQ3JV5PQ5XB6VT7R2&f=ifr&linkID=13e4a2a9221d1a1ecb0a7cdaa12f518c&t=ananke0d-20&tracking_id=ananke0d-20",size:1},
        {url:"https://rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=gift_certificates&banner=127JF9E4530CSFRCY4R2&f=ifr&linkID=e972a2aecf91a2718920b2c5ce7e5aa6&t=ananke0d-20&tracking_id=ananke0d-20",size:1},
        {url:"https://rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=pets&banner=0KVV7KEDT05D1PP85PR2&f=ifr&linkID=e421274a055898aa34ebb0afe6ddc930&t=ananke0d-20&tracking_id=ananke0d-20",size:1},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B003PWS9AI&asins=B003PWS9AI&linkId=7a09cb8b1ca453ca311948266436f4f6&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B06Y1YD5W7&asins=B06Y1YD5W7&linkId=a8fe6e848406bd0bd9f14dae95654cee&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B06Y1YD5W7&asins=B06Y1YD5W7&linkId=a8fe6e848406bd0bd9f14dae95654cee&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00BSYR7K8&asins=B00BSYR7K8&linkId=539f21e95c2e779b15d793bd336c8ee0&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B005OB3E30&asins=B005OB3E30&linkId=4010606728cb02de9c6dd14e7164ff38&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B004QBDO0M&asins=B004QBDO0M&linkId=87bf221c072675f82978eab8e338a111&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00MW8G62E&asins=B00MW8G62E&linkId=e29f79b6c85beba215a429adb3c878c5&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B001650NNW&asins=B001650NNW&linkId=63acf22530a42612d386c46fb62982eb&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B006W6YHYQ&asins=B006W6YHYQ&linkId=d9ad381b0764ee17eb72028f640d9c50&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketpla",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B07TMNGSN4&asins=B07TMNGSN4&linkId=f4bc0b304ea5303cac65b5d556cf88b4&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B004LLIKVU&asins=B004LLIKVU&linkId=4bf27217fceb0bbe8f9add7164238b77&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B01FIS88SY&asins=B01FIS88SY&linkId=fa89fe7332b243986c658b065ae8c1dd&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00XQ2XGAA&asins=B00XQ2XGAA&linkId=e7a35b8eb0d4f59b94dba8a56e0c3dee&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B07C9JYF2W&asins=B07C9JYF2W&linkId=81b7e798ae206642630a005a815afa0a&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B079JD7F7G&asins=B079JD7F7G&linkId=02a7a6af357ee0338f42ca605364bdbb&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00U2VQZDS&asins=B00U2VQZDS&linkId=60517a026d57f76d624400dc3076d914&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B01MQS7GFT&asins=B01MQS7GFT&linkId=0e4356305b8f9df1088c20c66ee876f5&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B073XTWVM6&asins=B073XTWVM6&linkId=12f991ce32a3c87c8beb5c8ce7b39d4a&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00HB55OHQ&asins=B00HB55OHQ&linkId=992cb0a50119cdecfe9f3f75fdea8ff2&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B07PXGQC1Q&asins=B07PXGQC1Q&linkId=ba01e6b41c8f9f11712fce7968d6edcc&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B06XWZWYVP&asins=B06XWZWYVP&linkId=5cbbb59845c29b6ce9b87d442b6018ce&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B06XWZWYVP&asins=B06XWZWYVP&linkId=5cbbb59845c29b6ce9b87d442b6018ce&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B07CVL2D2S&asins=B07CVL2D2S&linkId=a00532df2a5c4465e46b8094ff30ab83&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00GAC1D2G&asins=B00GAC1D2G&linkId=0933fc21b07aab928e52c8767388b795&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00F4CEHNK&asins=B00F4CEHNK&linkId=b12e045cfbfa35408258d015ccc5910b&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B0791TX5P5&asins=B0791TX5P5&linkId=522bfceeaaa28453556cb706cafdb551&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=&language=en_US&marketplace=amazon&region=US&placement=B07G9XZ83W&asins=B07G9XZ83W&linkId=2cf3bd95ed20accc88446a9655dc0236&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=&language=en_US&marketplace=amazon&region=US&placement=B07G9XZ83W&asins=B07G9XZ83W&linkId=2cf3bd95ed20accc88446a9655dc0236&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B07FZ8S74R&asins=B07FZ8S74R&linkId=27894b90c8dddc625d17d23203a8db18&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B01MZEEFNX&asins=B01MZEEFNX&linkId=da428bd4e8a97963a6a4aa7c5953955b&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B06WW64YM6&asins=B06WW64YM6&linkId=cba207de03f882d6f903d08a7fea4a52&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B06WW64YM6&asins=B06WW64YM6&linkId=cba207de03f882d6f903d08a7fea4a52&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B015JIAB7E&asins=B015JIAB7E&linkId=afb5942f071d3093ef0d4569649a7025&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B015JIAB7E&asins=B015JIAB7E&linkId=afb5942f071d3093ef0d4569649a7025&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B01GW2GNH8&asins=B01GW2GNH8&linkId=c1e3f7d95b816981e38812d3521bf57d&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00FO9U46W&asins=B00FO9U46W&linkId=cdfea78d08e2561e781d88f22934f8af&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B004S8F7QM&asins=B004S8F7QM&linkId=965bc4b09c91b1c6ef093a46336e4ea3&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B004S8F7QM&asins=B004S8F7QM&linkId=965bc4b09c91b1c6ef093a46336e4ea3&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00ABA0ZOA&asins=B00ABA0ZOA&linkId=be9ff7fcbb7802ece5dcac1fadf9576e&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00JM5GW10&asins=B00JM5GW10&linkId=8acf21010f1fa2a1eac3af5a3da40632&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00GAC1D2G&asins=B00GAC1D2G&linkId=ad4f556c5bfec062594a205b021f981b&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00ECHYTBI&asins=B00ECHYTBI&linkId=c9f3946095e4585b0f01afa1189baf58&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B00FMWWN6U&asins=B00FMWWN6U&linkId=d6494048dd77b8a8105d17e46f7da6d0&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B075XN1NZC&asins=B075XN1NZC&linkId=c9638585d43a14f168086f5ce7d4bee1&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B07JJZPZNS&asins=B07JJZPZNS&linkId=15cd1f66639a09fc4f2d2979cb66069e&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B07S8XNWWF&asins=B07S8XNWWF&linkId=8bbb4e4ad34b64a5cb10429463f69e37&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B01MYC1C2D&asins=B01MYC1C2D&linkId=f3e53b92fd9eb1374b05c969f76fc39d&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B075NC2MYB&asins=B075NC2MYB&linkId=856a300c0b98f565f311314765b49028&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B007YL6HY6&asins=B007YL6HY6&linkId=1e317401eebab3da69c6939fc0da9b90&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ananke0d-20&language=en_US&marketplace=amazon&region=US&placement=B007YL6HY6&asins=B007YL6HY6&linkId=1e317401eebab3da69c6939fc0da9b90&show_border=false&link_opens_in_new_window=true",size:2},
        {url:"",size:2},

      ],
      selectedUrl:{url:"https://rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=kindlerotating&f=ifr&linkID=54a0dec832ca343ed64dc7bac9a2bc55&t=ananke0d-20&tracking_id=ananke0d-20",size:1}
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({ showModal: true });
  }

  close() {
    this.setState({ showModal: false });
  }
  componentDidMount() {
    var item = this.state.urlAffilite[Math.floor(Math.random() * this.state.urlAffilite.length)];
    this.setState({selectedUrl:item});
  }

  render() {
    return (
      <div>
        <div>
          <Modal
            className="modal-container"
            show={this.state.showModal}
            onHide={this.close}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ height: "100vh" }}
            animation={true}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{textAlign:"center",margin:"0 auto"}}>
              <iframe
                className={this.state.selectedUrl.size == 1? 'bigads': 'smallads'}
                src={this.state.selectedUrl.url}
              ></iframe>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default AdsPro;
