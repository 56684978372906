import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "./CheckoutForm";
import HeaderAds from "./HeaderAds.js";
const axios = require("axios");

class buyPro extends React.Component {
  constructor(props) {
    super(props);
    this.state = { displayFooter: true };
    this.logout = this.logout.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }
  onBlur() {
    this.setState({ displayFooter: true });
  }
  onFocus() {
    this.setState({ displayFooter: false });
  }
  componentDidMount() {
    if (localStorage.getItem("darkmode") == 1) {
    } else {
    }
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("nome");
    localStorage.removeItem("googleToken");
    localStorage.removeItem("facebookToken");
    localStorage.removeItem("immagineProfilo");
    window.location.reload();
  }
  render() {
    return (
      <React.Fragment>
        {localStorage.getItem("token") == "undefined" ||
        !localStorage.getItem("token") ||
        localStorage.getItem("token") == null ? (
          <Redirect to="/login" />
        ) : (
          ""
        )}
        {localStorage.getItem("premium") == 2 ? (
          <Redirect to="/home"></Redirect>
        ) : (
          ""
        )}
        <div
          className="ads"
          style={{ height: "13vh", textAlign: "center", margin: "0 auto" }}
        >
          {localStorage.getItem("premium") != 2 && <HeaderAds></HeaderAds>}
        </div>
        <div
          style={{
            textAlign: "center",
            height: "77vh"
          }}
        >
          {localStorage.getItem("premium") != 1 && (
            <div
              className="buypro"
              style={{
                margin: "0 auto",
                textAlign: "left",
                paddingLeft: "5%",
                marginBottom: "5%"
              }}
            >
              <p style={{ fontSize: "1.4em" }}>Pro Plans</p>
              <b>Dark Mode</b> (0.89$)
              <ul style={{ fontSize: "1.1em", paddingLeft: "5%" }}>
                <li>Switch to dark mode whenever you want</li>
              </ul>
              <StripeProvider apiKey="pk_live_LoAx2yE8zFPTINLNcrcAg5lf00UIk9DLeL">
                <div
                  className="example"
                  style={{ width: "90%", paddingLeft: "5%" }}
                >
                  <Elements>
                    <CheckoutForm
                      methodFocus={this.onFocus}
                      methodBlur={this.onBlur}
                      pro={false}
                    />
                  </Elements>
                </div>
              </StripeProvider>
            </div>
          )}
          <div
            className="buypro"
            style={{
              margin: "0 auto",
              textAlign: "left",
              paddingLeft: "5%"
            }}
          >
            <b>No Ads + Dark Mode</b> (1.49$)
            <ul style={{ fontSize: "1.1em", paddingLeft: "5%" }}>
              <li>
                Delete banners and full-screen <br></br>advertisment
              </li>
              <li>
                Switch to dark mode whenever <br />
                you want
              </li>
            </ul>
            <StripeProvider apiKey="pk_live_LoAx2yE8zFPTINLNcrcAg5lf00UIk9DLeL">
              <div
                className="example"
                style={{ width: "90%", paddingLeft: "5%" }}
              >
                <Elements>
                  <CheckoutForm
                    methodFocus={this.onFocus}
                    methodBlur={this.onBlur}
                    pro={true}
                  />
                </Elements>
              </div>
            </StripeProvider>
          </div>
        </div>
        {localStorage.getItem("darkmode") != 1 && (
          <div className="bottoni" style={{ height: "100%" }}>
            <div
              className="col-footer-left"
              style={{ borderRight: "1px solid #b2b2b2" }}
            >
              {localStorage.getItem("premium") != 2 && (
                <Link to={`/buy-pro/`}>
                  <p className="text-ads blink-1" style={{textDecoration:"underline"}}>
                    <b>Stop Ads!</b>
                    <br />
                    <span style={{ fontWeight: "300" }}>Upgrade Pro</span>
                  </p>
                </Link>
              )}
              {localStorage.getItem("premium") == 2 && (
                <p className="text-ads">
                  <b>You are</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Pro member</span>
                </p>
              )}
            </div>
            <div
              className="col-footer-center"
              style={{ borderRight: "1px solid #b2b2b2", textAlign: "center" }}
            >
              <Link to={`/add-task/`}>
                <p className="text-task" style={{ textAlign: "center" }}>
                  <b style={{ fontSize: "1.4em" }}>&#43;</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Add Task</span>
                </p>
              </Link>
            </div>

            <div className="col-footer-right">
              <Link to={"/profilo"}>
                {" "}
                <img
                  style={{ marginBottom: "20%", width: "50%" }}
                  src={process.env.PUBLIC_URL + "/user.svg"}
                ></img>
              </Link>
            </div>
          </div>
        )}
        {localStorage.getItem("darkmode") == 1 && (
          <div className="bottoni" style={{ height: "100%" }}>
            <div
              className="col-footer-left"
              style={{ borderRight: "1px solid #b2b2b2" }}
            >
              {localStorage.getItem("premium") != 2 && (
                <Link to={`/buy-pro/`}>
                  <p className="text-ads-dark blink-1" style={{textDecoration:"underline"}}>
                    <b>Stop Ads!</b>
                    <br />
                    <span style={{ fontWeight: "300" }}>Upgrade Pro</span>
                  </p>
                </Link>
              )}
              {localStorage.getItem("premium") == 2 && (
                <p className="text-ads-dark">
                  <b>You are</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Pro member</span>
                </p>
              )}
            </div>
            <div
              className="col-footer-center"
              style={{ borderRight: "1px solid #b2b2b2", textAlign: "center" }}
            >
              <Link to={`/add-task/`}>
                <p className="text-task-dark" style={{ textAlign: "center" }}>
                  <b style={{ fontSize: "1.4em" }}>&#43;</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Add Task</span>
                </p>
              </Link>
            </div>

            <div className="col-footer-right">
              <Link to={"/profilo"}>
                {" "}
                <img
                  style={{ marginBottom: "20%", width: "50%" }}
                  src={process.env.PUBLIC_URL + "/dark/user.svg"}
                ></img>
              </Link>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default buyPro;
