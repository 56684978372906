import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state ={disabled:false,payment:false};
    this.submit = this.submit.bind(this);
  }
  async submit(ev) {
    this.setState({disabled:true});
    let { token } = await this.props.stripe.createToken({ name: "Name" });
    if (!this.props.pro) {
      fetch("https://app.ananke-app.com/chargeDark", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({ token: token.id ,email:localStorage.getItem("email")})
      })
        .then(res => res.json())
        .then(
          result => {
            if (result.status == "succeeded") {
              localStorage.setItem("premium",1);
              this.setState({payment:1});
              window.location.reload();
              
            } else {
              alert("Errore nella transazione");
            }
          },
          error => {
            alert(error);
            return;
          }
        );
    } else {
      fetch("https://app.ananke-app.com/chargePro", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({ token: token.id,email:localStorage.getItem("email") })
      })
        .then(res => res.json())
        .then(
          result => {
            if (result.status == "succeeded") {
              localStorage.setItem("premium",2);
              this.setState({payment:2});
              window.location.reload();
            } else {
              alert("Errore nella transazione");
            }
          },
          error => {
            alert(error);
            return;
          }
        );
    }
  }

  render() {
    return (
      <div className="checkout">
        <CardElement onFocus={this.props.methodFocus} onBlur={this.props.methodBlur}/>
        <button className="bottone-buy" onClick={this.submit} disabled={this.state.disabledPay}>
          Upgrade
        </button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
