import React from "react";
import Login from "./Login.js";
import Overview from "./overView.js";
import { BrowserRouter as Router, Route } from "react-router-dom";
import addTask from "./addTask.js";
import "./App.css";
import Profilo from "./Profilo.js";
import { BrowserView, MobileView } from "react-device-detect";
import buyPro from "./buyPro.js";
import PrivacyPolicy from "./PrivacyPolicy.js";

import TermsofService from "./TermsofService.js";

import Helmet from "react-helmet";

class App extends React.Component {
  render() {
    return (
      <div className="ananke-body">
        <Helmet
          bodyAttributes={
            localStorage.getItem("darkmode") == 1
              ? { style: "background-color : #3c3c3b;color:white" }
              : { style: "background-color : #fff;color:black" }
          }
        />

        <MobileView>
          <div className="ananke-app">
            <Router>
              <Route exact path="/" exact component={Login} />
              <Route exact path="/login/" component={Login} />
              <Route exact path="/home" component={Overview} />
              <Route exact path="/add-task" component={addTask} />
              <Route exact path="/buy-pro" component={buyPro} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                exact
                path="/terms-of-service"
                component={TermsofService}
              />
              <Route exact path="/profilo" component={Profilo} />
            </Router>
          </div>
          <div
            className="anake-lending"
            style={{ textAlign: "center", marginTop: "20%" }}
          >
            <h1>Turn your smartphone vertically!</h1>
            <br></br>
            <img
              src={process.env.PUBLIC_URL + "/turn-vertically.svg"}
              style={{ width: "10%" }}
            ></img>
          </div>
        </MobileView>
        <BrowserView>
          <div>
            <Router>
              <Route path="/" component={<h1>Landing Page</h1>} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/terms-of-service" component={TermsofService} />
            </Router>
          </div>
        </BrowserView>
      </div>
    );
  }
}

export default App;
