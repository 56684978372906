import React from "react";
import { Form } from "react-bootstrap";
import { GoogleLogin } from "react-google-login";
import {
  BrowserRouter as Router,
  Redirect,Link
} from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loginEffettuato: false, checkPolicy: true };
    this.eseguiLogin = this.eseguiLogin.bind(this);
    this.facebookResponse = this.facebookResponse.bind(this);
    this.changeCheck = this.changeCheck.bind(this);
  }

  onFailure = error => {
    console.log("Fail" + JSON.stringify(error));
  };
  changeCheck (event){
    this.setState({checkPolicy:!event.target.checked});
  }
  facebookResponse = response => {
    localStorage.setItem("facebookToken", response);
    this.eseguiLoginFacebook(response);
  };
  googleResponse = response => {
    localStorage.setItem("googleToken", response);
    this.eseguiLogin(response);
  };
  eseguiLoginFacebook(response) {
    var params = {
      token: response
    };
    fetch("https://app.ananke-app.com/loginFacebook", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(res => res.json())
      .then(
        result => {
          if (result.success) {
            localStorage.setItem("nome", result.name);
            localStorage.setItem("immagineProfilo", result.immagineProfilo);
            localStorage.setItem("email", result.email);
            localStorage.setItem("token", result.token);
            localStorage.setItem("premium", result.premium);
            localStorage.setItem("darkmode", 0);
            this.setState({ loginEffettuato: true });
          }
        },
        error => {
          alert(error);
          this.setState({
            error
          });
        }
      );
  }

  eseguiLogin(response) {
    var params = {
      token: response
    };
    fetch("https://app.ananke-app.com/loginGoogle", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(res => res.json())
      .then(
        result => {
          if (result.success) {
            localStorage.setItem("nome", result.name);
            localStorage.setItem("immagineProfilo", result.immagineProfilo);
            localStorage.setItem("email", result.email);
            localStorage.setItem("token", result.token);
            localStorage.setItem("premium", result.premium);
            localStorage.setItem("darkmode", 0);
            this.setState({ loginEffettuato: true });
          }
        },
        error => {
          alert(error);
          this.setState({
            error
          });
        }
      );
  }
  render() {
    return (
      <React.Fragment>
        <div className="ads" style={{ height: "13vh" }}>
          {localStorage.getItem("premium") != 2 && <p></p>}
        </div>
        {localStorage.getItem("token") == "undefined" ||
        !localStorage.getItem("token") ||
        localStorage.getItem("token") == null ? (
          ""
        ) : (
          <Redirect to="/home" />
        )}
        {this.state.loginEffettuato ? <Redirect to="/home" /> : ""}
        <div
          className=""
          style={{ margin: "0 auto", width: "100%", textAlign: "center" }}
        >
          <img
            style={{
              height: "80%",
              width: "50%",
              margin: "0 auto",
              marginTop: "15%"
            }}
            src={process.env.PUBLIC_URL + "/logo.svg"}
            className=""
          />
        </div>
        <div className="" style={{ marginTop: "25%", marginLeft: "25%" }}>
          <span style={{ fontSize: "1.6em", color: "#b5b5b5" }}>
            Take Advantage of<br></br> your free minutes
          </span>
        </div>
        <div
          style={{
            marginTop: "25%",
            position: "fixed",
            bottom: "5%",
            left: "0",
            width: "100%"
          }}
        >
          <div
            style={{
              display: "inline-block",
              width: "50%",
              margin: "0 auto",
              textAlign: "center"
            }}
          >
            <GoogleLogin
              clientId="220277328894-26ecpitp9efes1j6hijj7gtus97mpd8h.apps.googleusercontent.com"
              buttonText="Login with Google"
              render={renderProps => (
                <button
                  className={this.state.checkPolicy==true ? "buttonSocial-disabled" : "buttonSocial"}
                  disabled={this.state.checkPolicy}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <i className="fab fa-google"></i> Login with Google
                </button>
              )}
              className="my-google-button-class"
              disabled={this.state.checkPolicy}
              onSuccess={this.googleResponse}
              onFailure={this.onFailure}
            />
          </div>

          <div
            style={{
              display: "inline-block",
              width: "50%",
              margin: "0 auto",
              textAlign: "center"
            }}
          >
            <FacebookLogin
              appId="396019084301589"
              disabled={this.state.checkPolicy}
              autoLoad={false}
              buttonText="Facebook"
              fields="name,email,picture"
              render={renderProps => (
                <button
                  className={this.state.checkPolicy==true ? "buttonSocial-disabled" : "buttonSocial"}
                  disabled={this.state.checkPolicy}
                  onClick={renderProps.onClick}
                >
                  {" "}
                  <i className="fab fa-facebook-f"></i> Login with Facebook
                </button>
              )}
              callback={this.facebookResponse}
            />
          </div>
          <Form.Check
            type="checkbox"
            style={{marginLeft:"4%",marginTop:"2%",marginRight:"4%"}}
            id="dfds"
            onChange={this.changeCheck}
            label="Accetta le condizione della privacy policy"
          />
          <span style={{marginLeft:"4%"}}><Link to="/"><a href="#">Terms of use</a></Link></span>
          <span style={{marginLeft:"4%"}}><Link to="/"><a href="#">Privacy Policy</a></Link></span>

        </div>
      </React.Fragment>
    );
  }
}
export default Login;
