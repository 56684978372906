import React, { Component  } from "react";
import { Modal } from "react-bootstrap";

class ModalError extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: true
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({ showModal: true });
  }

  close() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div>
        <div>
          <Modal
            className="modal-container"
            show={this.state.showModal}
            onHide={this.close}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ height: "100%",backgroundColor:"white" }}
            animation={true}
          >
            <Modal.Header style={{backgroundColor:"#706f6f",color:"white",border:"0"}} closeButton></Modal.Header>
            <Modal.Body style={{backgroundColor:"#706f6f",color:"white",textAlign:"center"}}>
                <h2 style={{fontWeight:"bold"}}>Oops!</h2>
                <br></br>
                <br></br>
                <p style={{fontSize:"1.4em",lineHeight:"1.2em"}}>It's just an <br></br> 
                <span style={{fontWeight:"bold"}}>{this.props.messageError}</span><br></br>
                but <b>I can help you</b>!</p>
                <a href="mailto:jacopo@slumdesign.com"><button style={{fontSize:"1.3em",border:0,marginTop:"30px",padding:"10px",backgroundColor:"#4FA400",color:"white"}}>Send e-mail to tech assistance</button></a>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default ModalError;
