import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import AdsPro from "./ModalAds.js";
import HeaderAds from "./HeaderAds.js";
import ModalError from "./ModalError.js";

class Overview extends React.Component {
  //Costruttore
  constructor(props) {
    super(props);
    this.state = {
      selectedTask: null,
      selectedTime: null,
      darkmodepath: "",
      homeView: true,
      DonePages: false,
      filteredTask: [],
      errorModal: false,
      alreadyView: [],
      loaded: false,
      disabledButton: true,
      showLogoHome: true,
      immagineProfilo: "img-profilo scale-in-center-logo-image ",
      testoProfilo: "scale-in-center-logo-image",
      classeLogoHome: "logo",
      allDisabledButtons: true,
      disabledButtons: [false, false, false, false, false, false],
      scaleInTestView: "scale-in-center-logo-text"
    };
    this.caricaTask = this.caricaTask.bind(this);
    this.clicKButton = this.clicKButton.bind(this);
    this.visualizzaHome = this.visualizzaHome.bind(this);
    this.findTask = this.findTask.bind(this);
    this.nascondiLogo = this.nascondiLogo.bind(this);
    this.disabilitaPulsanti = this.disabilitaPulsanti.bind(this);
    this.clickDone = this.clickDone.bind(this);
    this.reloadHistory = this.reloadHistory.bind(this);
  }

  reloadHistory() {
    this.props.history.push("/");
  }
  //Algoritmo per trovare il task con il rapporto giusto da essere mostrato
  findTask(value) {
    let filteredTask = [];
    //Filtro i task con il tempo uguale a quello selezionato
    this.state.task.forEach(task => {
      if (task.tempo == value) {
        filteredTask.push(task);
      }
    });
    if (!filteredTask || filteredTask.length <= 0) {
      //alert("non ci sono task da visualizzare con lo stesso orario");
      return true;
    }
    //Trovo tutti i task che hanno una priorità 1 e non sono già stati visti.
    for (var i = 0; i < filteredTask.length; i++) {
      if (
        !this.state.alreadyView.includes(filteredTask[i]) &&
        filteredTask[i].priorita == 0 &&
        filteredTask[i].done == 0
      ) {
        this.setState({ SelectedTask: filteredTask[i] });
        this.setState({
          alreadyView: [...this.state.alreadyView, filteredTask[i]]
        });
        return true;
      }
    }
    return false;
  }
  //Gestione del click sul bottone
  clicKButton(value, id) {
    if (!this.state.disabledButtons[id]) {
      return;
    }
    this.setState({ selectedTime: value });
    if (this.state.immagineProfilo != "none" && this.testoProfilo != "none") {
      this.setState({ immagineProfilo: "scale-out-center" });
      this.setState({ testoProfilo: "scale-out-center" });
    }
    setTimeout(() => {
      this.setState({ immagineProfilo: "none", testoProfilo: "none" });
    }, 100);

    if (!this.findTask(value)) {
      this.setState({ alreadyView: [] });
      setTimeout(() => {
        this.findTask(value);
      }, 200);
    }

    /*Se arriva qui sono già stati visti tutti i task e reimposto la lista dei task da vedere
    let removedItems=[];
    this.state.alreadyView.forEach(task => {
      if(task.tempo == this.state.selectedTime){
        removedItems = [...removedItems,task];    
      }
    });
    let appoggioAlready = this.state.alreadyView;
    this.state.alreadyView.forEach(task => {
      if(!(removedItems.includes(task))){
        appoggioAlready = [...appoggioAlready,task];
      }
    });*/
  }
  //Visualizza la home
  visualizzaHome() {
    this.setState({ homeView: true });
  }
  //Carica i task da Back end
  caricaTask() {
    fetch("https://app.ananke-app.com/getTask", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token"),
        credentials: "include"
      },
      body: ""
    })
      .then(res => res.json())
      .then(
        result => {
          let taskCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (result[i].done == 0) taskCount++;
          }
          this.setState({ task: result, loaded: true, taskCounter: taskCount });
          this.disabilitaPulsanti();
        },
        error => {
          this.setState({
            errorModal: true,
            erroMessage: "Error in loading Task"
          });
          return;
        }
      );
  }
  //Start
  componentDidMount() {
    //this.settaTokenDiTest();
    this._isMounted = true;
    if (localStorage.getItem("darkmode") == 1) {
      this.setState({ darkmodepath: "dark/" });
    } else {
    }
    if (
      localStorage.getItem("token") == "undefined" ||
      !localStorage.getItem("token") ||
      localStorage.getItem("token") == null
    ) {
      return;
    }
    this.caricaTask();
    setTimeout(this.nascondiLogo, 0);
  }
  //Fix strange problem
  componentWillUnmount() {
    this._isMounted = false;
  }
  //Funzione per disabilitare i pulsanti in caso non ci siano attività con durata uguale a quella stimata
  disabilitaPulsanti() {
    let arrayAppogio = [];
    let allDisabled = true;
    this.state.task.forEach(task => {
      if (task.tempo == 5 && task.done == 0) {
        allDisabled = false;
        arrayAppogio[0] = true;
      }
      if (task.tempo == 10 && task.done == 0) {
        allDisabled = false;
        arrayAppogio[1] = true;
      }
      if (task.tempo == 20 && task.done == 0) {
        arrayAppogio[2] = true;
        allDisabled = false;
      }
      if (task.tempo == 30 && task.done == 0) {
        arrayAppogio[3] = true;
        allDisabled = false;
      }
      if (task.tempo == 45 && task.done == 0) {
        allDisabled = false;
        arrayAppogio[4] = true;
      }
      if (task.tempo == 60 && task.done == 0) {
        arrayAppogio[5] = true;
        allDisabled = false;
      }
    });
    for (let i = 0; i < 6; i++) {
      if (!arrayAppogio[i] || arrayAppogio[i] == null) {
        arrayAppogio[i] = false;
      }
    }
    this.setState({
      disabledButtons: arrayAppogio,
      selectedTime: 0,
      allDisabledButtons: allDisabled
    });
  }
  //Nasconde il logo in iniziale di asktask
  nascondiLogo() {
    this.setState({ classeLogoHome: "scale-out-center" });
    setTimeout(() => {
      this.setState({ showLogoHome: false });
    }, 500);
  }
  //Gestione click sul done
  clickDone() {
    //Chiamata al servizio di done
    //Richiamare caricaTask
    var params = {
      id: this.state.SelectedTask.id
    };
    this.setState({ errorModal: false });
    fetch("https://app.ananke-app.com/taskDone", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token"),
        credentials: "include"
      },
      body: JSON.stringify(params)
    })
      .then(res => res.json())
      .then(
        result => {
          this.setState({ DonePages: true });
          this.caricaTask();
        },
        error => {
          this.setState({
            errorModal: true,
            erroMessage: "Error in click done"
          });
          return;
        }
      );

    setTimeout(() => {
      this.setState({
        DonePages: false,
        SelectedTask: false,
        showLogoHome: false,
        immagineProfilo: "img-profilo scale-in-center-logo-image ",
        testoProfilo: "scale-in-center-logo-image"
      });
    }, 2000);
  }
  render() {
    return (
      <React.Fragment>
        {localStorage.getItem("token") == "undefined" ||
        !localStorage.getItem("token") ||
        localStorage.getItem("token") == null ? (
          <Redirect to="/login" />
        ) : (
          ""
        )}{" "}
        <div
          className="ads"
          style={{ height: "13vh", textAlign: "center", margin: "0 auto" }}
        >
          {localStorage.getItem("premium") != 2 && <HeaderAds></HeaderAds>}
        </div>
        {localStorage.getItem("premium") != 2 && <AdsPro></AdsPro>}
        {this.state.errorModal && (
          <ModalError messageError={this.state.erroMessage}></ModalError>
        )}
        <div
          className="immagine-ask"
          style={{
            textAlign: "center",
            height: "42vh",
            backgroundColor: ""
          }}
        >
          {this.state.showLogoHome && (
            <img
              alt="logo-ananake"
              style={{ height: "50%", margin: "0 auto", marginTop: "10%" }}
              src={process.env.PUBLIC_URL + "/logo.svg"}
              className={this.state.classeLogoHome}
            />
          )}
          {!this.state.showLogoHome && !this.state.SelectedTask && (
            <div className="row">
              <div className="col-5">
                <img
                  src={localStorage.getItem("immagineProfilo")}
                  className={this.state.immagineProfilo}
                  style={{ borderRadius: "50%", marginLeft: "50%" }}
                />
              </div>
              <div className="col-6">
                <p
                  style={{
                    fontSize: "1.1em",
                    marginTop: "15%",
                    textAlign: "left",
                    lineHeight: "1.2em"
                  }}
                  className={this.state.testoProfilo}
                >
                  <b>{localStorage.getItem("nome")}</b>
                  <br></br>
                  <span>
                    Total tasks: <b>{this.state.taskCounter}</b>
                  </span>
                </p>
              </div>
            </div>
          )}

          {!this.state.showLogoHome &&
            !this.state.SelectedTask &&
            !this.state.allDisabledButtons && (
              <div className="col-12">
                <p
                  style={{
                    fontSize: "1.4em",
                    fontWeight: "bold",
                    marginTop: "10%",
                    lineHeight: "1.2em"
                  }}
                  className="scale-in-center-logo-text"
                >
                  How many minutes <br></br>do you have?
                </p>
              </div>
            )}
          {!this.state.showLogoHome &&
            !this.state.SelectedTask &&
            this.state.allDisabledButtons && (
              <div className="col-12">
                <p
                  style={{
                    fontSize: "1.4em",
                    marginTop: "10%",
                    lineHeight: "1.2em"
                  }}
                  className="scale-in-center-logo-text"
                >
                  You have <span style={{ fontWeight: "600" }}>no tasks</span>!
                  <br />
                  <span style={{ fontWeight: "600" }}>Add</span> a new task{" "}
                  <span style={{ fontWeight: "600" }}>below</span>.
                </p>
              </div>
            )}

          {this.state.SelectedTask && !this.state.DonePages && (
            <p
              style={{
                fontSize: "1.2em",
                fontWeight: "200",
                paddingTop: "18%",
                paddingLeft: "15%",
                paddingRight: "15%",
                textAlign: "left"
              }}
              className={this.state.scaleInTestView}
            >
              <span style={{ textDecoration: "underline" }}>
                In{" "}
                <b style={{ fontWeight: "bold" }}>
                  {this.state.selectedTime} minutes
                </b>{" "}
                you could:{" "}
              </span>
              <br></br>
              <span
                style={{
                  fontWeight: "200",
                  wordBreak: "break-word",
                  lineHeight: "1.2em"
                }}
              >
                {this.state.SelectedTask.nome}
              </span>
            </p>
          )}

          {this.state.SelectedTask && !this.state.DonePages && (
            <img
              style={{ width: "22%" }}
              className={this.state.scaleInTestView}
              onClick={this.clickDone}
              src={
                process.env.PUBLIC_URL +
                "/button/" +
                this.state.darkmodepath +
                "button-done.svg"
              }
            ></img>
          )}

          {this.state.DonePages && (
            <div
              style={{
                textAlign: "center",
                fontSize: "1.2em",
                lineHeight: "1.2em"
              }}
            >
              <img
                style={{ width: "22%", marginTop: "10%" }}
                className=""
                onClick={this.clickDone}
                src={process.env.PUBLIC_URL + "/button-check.svg"}
              ></img>
              <br />
              <br />
              <p>
                <b style={{ fontWeight: "bold" }}>Great </b>
                {localStorage.getItem("nome")}
                <br></br>You have{" "}
                <b style={{ fontWeight: "bold" }}>one problem less</b>
              </p>
            </div>
          )}
        </div>
        <div className="box-bottoni" style={{ height: "35vh", width: "100%" }}>
          <div className="col3-left-extreme"></div>
          <div className="col3-left">
            <img
              onClick={() => this.clicKButton(5, 0)}
              src={
                this.state.selectedTime == 5
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-skip.svg"
                  : this.state.disabledButtons[0] == false
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-5-disabled.svg"
                  : process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-5.svg"
              }
              value="5"
              style={{ width: "90%", marginBottom: "10px", cursor: "pointer" }}
            />

            <img
              onClick={() => this.clicKButton(30, 3)}
              src={
                this.state.selectedTime == 30
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-skip.svg"
                  : this.state.disabledButtons[3] == false
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-30-disable.svg"
                  : process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-30.svg"
              }
              value="10"
              style={{ width: "90%", cursor: "pointer" }}
            />
          </div>
          <div className="col3-center">
            <img
              onClick={() => {
                this.clicKButton(10, 1);
              }}
              src={
                this.state.selectedTime == 10
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-skip.svg"
                  : this.state.disabledButtons[1] == false
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-10-disabled.svg"
                  : process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-10.svg"
              }
              value="20"
              style={{ width: "90%", marginBottom: "10px", cursor: "pointer" }}
            />
            <img
              onClick={() => {
                this.clicKButton(45, 4);
              }}
              src={
                this.state.selectedTime == 45
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-skip.svg"
                  : this.state.disabledButtons[4] == false
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-45-disabled.svg"
                  : process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-45.svg"
              }
              value="30"
              style={{ width: "90%", cursor: "pointer" }}
            />
          </div>
          <div className="col3-right">
            <img
              onClick={() => {
                this.clicKButton(20, 2);
              }}
              src={
                this.state.selectedTime == 20
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-skip.svg"
                  : this.state.disabledButtons[2] == false
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-20-disabled.svg"
                  : process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-20.svg"
              }
              value="45"
              style={{ width: "90%", marginBottom: "10px", cursor: "pointer" }}
            />
            <img
              onClick={() => {
                this.clicKButton(60, 5);
              }}
              src={
                this.state.selectedTime == 60
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-skip.svg"
                  : this.state.disabledButtons[5] == false
                  ? process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-60-disabled.svg"
                  : process.env.PUBLIC_URL +
                    "/button/" +
                    this.state.darkmodepath +
                    "button-60.svg"
              }
              value="60"
              style={{ width: "90%", cursor: "pointer" }}
            />
          </div>
        </div>
        {localStorage.getItem("darkmode") != 1 && (
          <div className="bottoni" style={{ height: "100%" }}>
            <div
              className="col-footer-left"
              style={{ borderRight: "1px solid #b2b2b2" }}
            >
              {localStorage.getItem("premium") != 2 && (
                <Link to={`/buy-pro/`}>
                  <p className="text-ads blink-1">
                    <b>Stop Ads!</b>
                    <br />
                    <span style={{ fontWeight: "300" }}>Upgrade Pro</span>
                  </p>
                </Link>
              )}
              {localStorage.getItem("premium") == 2 && (
                <p className="text-ads">
                  <b>You are</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Pro member</span>
                </p>
              )}
            </div>
            <div
              className="col-footer-center"
              style={{ borderRight: "1px solid #b2b2b2", textAlign: "center" }}
            >
              <Link to={`/add-task/`}>
                <p className="text-task" style={{ textAlign: "center" }}>
                  <b style={{ fontSize: "1.4em" }}>&#43;</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Add Task</span>
                </p>
              </Link>
            </div>
            {this.state.SelectedTask && (
              <div className="col-footer-right">
                <span onClick={this.reloadHistory}>
                  {" "}
                  <img
                    style={{ marginBottom: "20%", width: "50%" }}
                    src={process.env.PUBLIC_URL + "/button-home.svg"}
                  ></img>
                </span>
              </div>
            )}

            {!this.state.SelectedTask && (
              <div className="col-footer-right">
                <Link to={"/profilo"}>
                  {" "}
                  <img
                    style={{ marginBottom: "20%", width: "50%" }}
                    src={process.env.PUBLIC_URL + "/settings.svg"}
                  ></img>
                </Link>
              </div>
            )}
          </div>
        )}
        {localStorage.getItem("darkmode") == 1 && (
          <div className="bottoni" style={{ height: "100%" }}>
            <div
              className="col-footer-left"
              style={{ borderRight: "1px solid #b2b2b2" }}
            >
              {localStorage.getItem("premium") != 2 && (
                <Link to={`/buy-pro/`}>
                  <p className="text-ads-dark blink-1">
                    <b>Stop Ads!</b>
                    <br />
                    <span style={{ fontWeight: "300" }}>Upgrade Pro</span>
                  </p>
                </Link>
              )}
              {localStorage.getItem("premium") == 2 && (
                <p className="text-ads-dark">
                  <b>You are</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Pro member</span>
                </p>
              )}
            </div>
            <div
              className="col-footer-center"
              style={{ borderRight: "1px solid #b2b2b2", textAlign: "center" }}
            >
              <Link to={`/add-task/`}>
                <p className="text-task-dark" style={{ textAlign: "center" }}>
                  <b style={{ fontSize: "1.4em" }}>&#43;</b>
                  <br />
                  <span style={{ fontWeight: "300" }}>Add Task</span>
                </p>
              </Link>
            </div>

            {this.state.SelectedTask && !this.state.DonePages && (
              <div className="col-footer-right">
                <span onClick={this.reloadHistory}>
                  {" "}
                  <img
                    style={{ marginBottom: "20%", width: "50%" }}
                    src={process.env.PUBLIC_URL + "/dark/button-home.svg"}
                  ></img>
                </span>
              </div>
            )}

            {!this.state.SelectedTask && !this.state.DonePages && (
              <div className="col-footer-right">
                <Link to={"/profilo"}>
                  {" "}
                  <img
                    style={{ marginBottom: "20%", width: "50%" }}
                    src={process.env.PUBLIC_URL + "/dark/settings.svg"}
                  ></img>
                </Link>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default Overview;
