import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

function onUpdateHandler(registration) {
  const waitingServiceWorker = registration.waiting;
  if (waitingServiceWorker) {
    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
  }

  const link = document.createElement("a");
  link.classList.add("update-notification");
  link.setAttribute("href", "#");
  link.innerHTML = "Update is available. Click here to install.";

  link.addEventListener("click", e => {
    e.preventDefault();
    window.location.reload();
  });

  document.querySelector("body").appendChild(link);
}

serviceWorker.register({
  onUpdate: onUpdateHandler
});

ReactDOM.render(<App />, document.getElementById("root"));
